module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-google-analytics@2.3.13_d034f24cb816a237a9251d0efffb95fd/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-177613845-1"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-theme-material-ui-top-layout@1.0.3_7fcfce455dde9c639ca39ea2766d116b/node_modules/gatsby-theme-material-ui-top-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-material-ui@2.1.10_gatsby@2.24.47/node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"stylesProvider":{"injectFirst":true}},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@2.4.23_gatsby@2.24.47/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"parliament","short_name":"parliament","start_url":"/","background_color":"#000","theme_color":"#000","display":"minimal-ui","icon":"src/svg/beehive-favicon.svg","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"4469d3814b7c2daffc965a7ad50cf3a6"},
    }]
